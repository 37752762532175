import moment from 'moment'


export default {
    /* moment from now */
    fromNow(value) {
        return moment(value).fromNow();
    }, 
    /* convert number into .2 K M format*/
    shortenNumber(num,def="") {
        if(num == null){
            return def;
        }
        num = num.toString().replace(/[^0-9.]/g, '');
        if (num < 1000) {
            return num;
        }
        let si = [
            {v: 1E3, s: "K"},
            {v: 1E6, s: "M"},
            {v: 1E9, s: "B"},
            {v: 1E12, s: "T"},
            {v: 1E15, s: "P"},
            {v: 1E18, s: "E"}
            ];
        let index;
        for (index = si.length - 1; index > 0; index--) {
            if (num >= si[index].v) {
                break;
            }
        }
        return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
    },
    pluralWord(word,revert){

        var plural = {
            '(quiz)$'               : "$1zes",
            '^(ox)$'                : "$1en",
            '([m|l])ouse$'          : "$1ice",
            '(matr|vert|ind)ix|ex$' : "$1ices",
            '(x|ch|ss|sh)$'         : "$1es",
            '([^aeiouy]|qu)y$'      : "$1ies",
            '(hive)$'               : "$1s",
            '(?:([^f])fe|([lr])f)$' : "$1$2ves",
            '(shea|lea|loa|thie)f$' : "$1ves",
            'sis$'                  : "ses",
            '([ti])um$'             : "$1a",
            '(tomat|potat|ech|her|vet)o$': "$1oes",
            '(bu)s$'                : "$1ses",
            '(alias)$'              : "$1es",
            '(octop)us$'            : "$1i",
            '(ax|test)is$'          : "$1es",
            '(us)$'                 : "$1es",
            '([^s]+)$'              : "$1s"
        };
    
        var singular = {
            '(quiz)zes$'             : "$1",
            '(matr)ices$'            : "$1ix",
            '(vert|ind)ices$'        : "$1ex",
            '^(ox)en$'               : "$1",
            '(alias)es$'             : "$1",
            '(octop|vir)i$'          : "$1us",
            '(cris|ax|test)es$'      : "$1is",
            '(shoe)s$'               : "$1",
            '(o)es$'                 : "$1",
            '(bus)es$'               : "$1",
            '([m|l])ice$'            : "$1ouse",
            '(x|ch|ss|sh)es$'        : "$1",
            '(m)ovies$'              : "$1ovie",
            '(s)eries$'              : "$1eries",
            '([^aeiouy]|qu)ies$'     : "$1y",
            '([lr])ves$'             : "$1f",
            '(tive)s$'               : "$1",
            '(hive)s$'               : "$1",
            '(li|wi|kni)ves$'        : "$1fe",
            '(shea|loa|lea|thie)ves$': "$1f",
            '(^analy)ses$'           : "$1sis",
            '((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$': "$1$2sis",        
            '([ti])a$'               : "$1um",
            '(n)ews$'                : "$1ews",
            '(h|bl)ouses$'           : "$1ouse",
            '(corpse)s$'             : "$1",
            '(us)es$'                : "$1",
            's$'                     : ""
        };
    
        var irregular = {
            'move'   : 'moves',
            'foot'   : 'feet',
            'goose'  : 'geese',
            'sex'    : 'sexes',
            'child'  : 'children',
            'man'    : 'men',
            'tooth'  : 'teeth',
            'person' : 'people',
            'this'  : 'these',
        };
    
        var uncountable = [
            'sheep', 
            'fish',
            'deer',
            'moose',
            'series',
            'species',
            'money',
            'rice',
            'information',
            'people',
            'equipment'
        ];
    
        // save some time in the case that singular and plural are the same
        if(uncountable.indexOf(word.toLowerCase()) >= 0)
          return word;

        var pattern;
        var replace;
        // check for irregular forms
        //console.log(word);
        for(var w in irregular){
          if(revert){
                pattern = new RegExp(irregular[w]+'$', 'i');
                replace = w;
          } else{ 
                pattern = new RegExp(w+'$', 'i');
                replace = irregular[w];
          }
          if(pattern.test(word))
            return word.replace(pattern, replace);
        }
        var array;
        if(revert) array = singular;
             else array = plural;
    
        // check for matches using regular expressions
        for(var reg in array){
          pattern = new RegExp(reg, 'i');
          if(pattern.test(word))
            return word.replace(pattern, array[reg]);
        }
        return word;
    },
    log(...args) {
        this.logEx("info",...args);
    },
    logError(...args) {
        this.logEx("error",...args);
    },

    logEx(mode,...args){
        var colors = {
            error:'color: red',
            info: "color:blue",
        }
        let messageConfig = '%c ';
        args.forEach((argument) => {
          const type = typeof argument;
          switch (type) {
              case 'bigint':
              case 'number':
              case 'boolean':
                  messageConfig += '%d   ';
                  break;
      
              case 'string':
                  messageConfig += '%s   ';
                  break;
      
              case 'object':
              case 'undefined':
              default:
                  messageConfig += '%o   ';
          }
        });
        console.log(messageConfig, colors[mode], ...args);
    },
    unitWord(word,num) {
        if(num>1){
            return this.pluralWord(word,false);
        }else{
            return word;
        }
    },
    getServerConfig(mode){
        /*var biServer = {
            prod: "https://services.mdl.io/api/bi",
            stage: "https://services.mdl.io/api/bi",
        }*/
        var ret = {
            endPoints: {
                mindfireOne: "https://8mdmei6e83.execute-api.us-west-1.amazonaws.com/" + mode ,
                bi:  process.env.VUE_APP_EDGE_SERVICE_ENDPOINT, // "https://services.mdl.io/api/bi",
                qrcode: "https://cntebx33x5.execute-api.us-west-1.amazonaws.com/stage/qrcode",
                card: "https://8mdmei6e83.execute-api.us-west-1.amazonaws.com/pass/api/card",
            },
        }
        return ret;
    },
    formatDate(date,format,offset=0,unit="days"){
        if(offset == 0){
            return moment(date).format(format);
        }else{
            return moment(date).add(offset,unit).format(format);
        }
    },
    readFileAsync(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
      
          reader.onload = () => {
            resolve(reader.result);
          };
      
          reader.onerror = reject;
      
          reader.readAsArrayBuffer(file);
        })
    },    
    arrayBufferToBase64( buffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    },
    arraymove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    },
    addslashes( str ) {
        return JSON.stringify(str).slice(1, -1);
    },
    IsValidURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    },
    UTCDateTime(){
        var d = new Date();
        return moment(d).utc().format("YYYY-MM-DD[T]HH:mm:ss[Z]"); // WHO WROTE THIS?  You're the ghost in the machine!  Should be HH not hh
    },
    ReplaceAll(str,search, replacement) {
        var target = str;
        return target.split(search).join(replacement);
    },
    asyncSleep(ms){
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    },
    pathInfo(s) {
        s=s.match(/(.*?\/)?(([^/]*?)(\.[^/.]+?)?)(?:[?#].*)?$/);
        return {path:s[1],file:s[2],name:s[3],ext:s[4]};
    }
};